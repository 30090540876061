<template>
  <div class="ddj-offer">
    <!-- <div class="main-Title bgff"><h2>上传记录</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Country:">
                <el-select
                  v-model="filter.country"
                  placeholder="Please select"
                  class="w100"
                  filterable
                  :clearable="true"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="pkg:">
                <el-input v-model="filter.pkg" placeholder="Please enter" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-button
                type="primary"
                size="mini"
                @click="getList('filter')"
                :loading="loading.list"
                >Search</el-button
              >
              <el-button type="warning" size="mini" @click="openAddDialog()">Create</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="pkg" prop="pkg"></el-table-column>
          <el-table-column label="dataStrategyCode" prop="dataStrategyCode"></el-table-column>
          <el-table-column label="device Count" prop="deviceCount" align="center"></el-table-column>
          <el-table-column label="oss地址" prop="ossSavedUrl"></el-table-column>
          <el-table-column label="更新时间" prop="updateTime"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" width="100" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="openDialog('edit', scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="dialog.title"
          :visible.sync="dialog.visible"
          width="40%"
          :close-on-click-modal="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="120px"
            :rules="rules"
          >
            <el-form-item label="deviceCount" class="mb25" prop="deviceCount">
              <el-input v-model="postData.deviceCount" clearable></el-input>
            </el-form-item>
            <el-form-item label="oss地址" class="mb25" prop="ossSavedUrl">
              <el-input
                type="textarea"
                v-model="postData.ossSavedUrl"
                clearable
                placeholder="Please ossSavedUrl"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')">取 消</el-button>
            <el-button
              type="primary"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <el-dialog
          :title="addDialog.title"
          :visible.sync="addDialog.visible"
          width="40%"
          :close-on-click-modal="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="120px"
            :rules="rules"
          >
            <el-form-item label="Country" prop="country">
              <el-select
                v-model="postData.country"
                size="small"
                placeholder="Please select"
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in options.country"
                  :key="item.countryCode"
                  :label="item.countryCode"
                  :value="item.countryCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="StrategyCode" class="mb25" prop="dataStrategyCode">
              <el-select v-model="postData.dataStrategyCode" clearable class="w100">
                <el-option
                  v-for="it in strategyList"
                  :key="it.id"
                  :label="it.dataStrategyCode"
                  :value="it.dataStrategyCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="pkg" class="mb25" prop="pkg">
              <el-input v-model="postData.pkg" clearable></el-input>
            </el-form-item>
            <el-form-item label="deviceCount" class="mb25" prop="deviceCount">
              <el-input v-model="postData.deviceCount" clearable></el-input>
            </el-form-item>
            <el-form-item label="oss地址" class="mb25" prop="ossSavedUrl">
              <el-input
                type="textarea"
                v-model="postData.ossSavedUrl"
                clearable
                placeholder="Please ossSavedUrl"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeAddDialog('postDataForm')">取 消</el-button>
            <el-button
              type="primary"
              @click="submitAddDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import optionData from '@/assets/js/optionData';
  import { sfPkgUploadPage, sfPkgUploadUpdate, sfPkgUploadAdd } from '@/api/algorithmSetting';
  import { fetchCountryList } from '@/api/DDJ/v3/country';
  import { getAlgStrategyList } from '@/api/setting';
  export default {
    components: {},
    data() {
      return {
        filter: {},
        options: {
          country: [],
          os: optionData.osOption,
        },
        strategyList: [],
        list: [],
        diallog: {
          updata: false,
        },
        loading: {
          list: false,
          submitBtn: false,
        },
        isUpdataPost: {
          id: '',
          isUpdate: '',
        },
        dialog: {
          visible: false,
          title: '--',
          type: '',
        },
        addDialog: {
          visible: false,
        },
        random: '',
        postData: {},
        rules: {
          dataStrategyCode: [{ required: true, message: 'StrategyCode不能为空' }],
          pkg: [{ required: true, message: 'pkg不能为空' }],
          country: [{ required: true, message: 'country不能为空' }],
          deviceCount: [{ required: true, message: 'deviceCount不能为空' }],
          ossSavedUrl: [{ required: true, message: 'oss地址不能为空' }],
        },
        pages: {
          page: 1,
          pageSize: 20,
        },
        pagesTotal: 0,
        isShowPage: false,
      };
    },
    mounted() {
      this.getCountry();
      this.getList();
      this.getAlgStrategy();
    },
    methods: {
      getAlgStrategy() {
        getAlgStrategyList().then((res) => {
          this.strategyList = res.result;
        });
      },
      // 国家
      getCountry() {
        fetchCountryList().then((res) => {
          this.options.country = res.result;
        });
      },
      // 获取列表
      getList(types) {
        if (types == 'filter') {
          this.pages.page = 1;
          this.isShowPage = false;
        }
        let query = Object.assign({}, this.filter, this.pages);
        this.loading.list = true;
        sfPkgUploadPage(query).then((res) => {
          if (res.code == 200) {
            let data = res.result;
            this.list = [];
            this.list = data.records;
            this.pagesTotal = data.total;
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
          this.loading.list = false;
          this.isShowPage = true;
        });
      },
      // 打开弹窗
      openDialog(type, row) {
        this.dialog.type = type;
        this.dialog.title = type === 'add' ? '添加' : '编辑';
        this.dialog.visible = true;
        if (row) {
          this.postData = Object.assign({}, row);
        }
        if (this.dialog.type === 'add') {
          this.postData = {};
        }
      },
      openAddDialog() {
        this.addDialog.title = '添加';
        this.addDialog.visible = true;
        this.postData = {};
      },
      closeAddDialog(formName) {
        this.$refs[formName].resetFields();
        this.addDialog.visible = false;
      },
      // 关闭弹窗
      closeDialog(formName) {
        this.$refs[formName].resetFields();
        this.dialog.visible = false;
      },
      // 提交弹窗
      submitDialog(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            sfPkgUploadUpdate(this.postData)
              .then((res) => {
                if (res.success === true) {
                  this.$message.success('操作成功!');
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.loading.submitBtn = false;
                  this.$message.error(res.message);
                }
              })
              .catch((e) => {
                this.loading.list = false;
                this.loading.submitBtn = false;
                this.$message.error(e);
              });
          }
        });
      },
      submitAddDialog(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            sfPkgUploadAdd(this.postData)
              .then((res) => {
                if (res.success === true) {
                  this.$message.success('操作成功!');
                  this.loading.submitBtn = false;
                  this.closeAddDialog(formName);
                  this.getList();
                } else {
                  this.loading.submitBtn = false;
                  this.$message.error(res.message);
                }
              })
              .catch((e) => {
                this.loading.list = false;
                this.loading.submitBtn = false;
                this.$message.error(e);
              });
          }
        });
      },
      // 分页监听
      handleCurrentChange(val) {
        this.pages.page = val;
        this.getList();
      },
    },
  };
</script>

<style></style>
